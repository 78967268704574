import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5ZM1.75 12c0-5.937 4.813-10.75 10.75-10.75S23.25 6.063 23.25 12 18.437 22.75 12.5 22.75 1.75 17.937 1.75 12ZM12.5 6.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9.75 8a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm1.417 5.75a1.917 1.917 0 0 0-1.917 1.917c0 .322.261.583.583.583h5.334a.583.583 0 0 0 .583-.583 1.917 1.917 0 0 0-1.917-1.917h-2.666ZM7.75 15.667a3.417 3.417 0 0 1 3.417-3.417h2.666a3.417 3.417 0 0 1 3.417 3.417c0 1.15-.933 2.083-2.083 2.083H9.833a2.083 2.083 0 0 1-2.083-2.083Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
