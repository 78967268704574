import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#18181B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 5a4 4 0 0 0 4 4M4 20l.62-3.1c.141-.706.212-1.06.341-1.389.115-.292.263-.57.443-.827.202-.29.457-.545.966-1.054L16 4c.545-.545.818-.818 1.112-.964a2 2 0 0 1 1.776 0c.294.146.567.419 1.112.964v0c.545.545.818.818.964 1.112a2 2 0 0 1 0 1.776c-.146.294-.419.567-.964 1.112l-9.63 9.63c-.51.51-.764.764-1.054.966-.257.18-.535.328-.827.443-.33.13-.683.2-1.39.341L4 20Z"
    />
  </svg>
)
export default SvgComponent
