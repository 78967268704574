/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'
import { produce } from 'immer'
import { useMemo } from 'react'
import { PolarArea } from 'react-chartjs-2'

import { getGraphFromRecord } from '@/lib'
import { formatLifeCategories } from '@/lib/utils'
import { useLanguageStore } from '@/providers'
import { LifeCategory, type Tables } from '@/supabase'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

export enum PolarAreaVariants {
  Default = 'default',
  Borderless = 'borderless',
  WithPoints = 'with-points',
}
interface PolarAreaChartProps {
  variant?: PolarAreaVariants
  data: Tables<'records'>
  previousData?: Tables<'records'>
  showCompare?: boolean
}

const DEFAULT_OPTIONS = {
  plugins: {
    legend: { display: false, position: 'bottom' as const },
  },
  scales: {
    r: {
      pointLabels: {
        callback: (label: string) => {
          return label.split(' ')[0]
        },
        display: true,
        centerPointLabels: true,
        padding: 0.5,
        font: {
          size: 18,
        },
      },
      grid: {
        color: 'rgba(0, 0, 0, 0.05)',
      },
      ticks: {
        color: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
}

const BORDERLESS_OPTIONS = {
  animation: false,
  scales: {
    r: {
      display: false,
    },
  },
  plugins: {
    legend: { display: false, position: 'bottom' as const },
    tooltip: { enabled: false },
  },
}

export const PolarAreaChart = ({
  variant = PolarAreaVariants.Default,
  data,
  showCompare = false,
  previousData,
}: PolarAreaChartProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const titles = formatLifeCategories(dict.life_categories)

  const getKeyWithLabel = (label: string): LifeCategory => {
    const key =
      Object.entries(titles).find(([_, value]) => value === label)?.[0] ?? ''
    return key as LifeCategory
  }

  const getDiff = (
    currentRecord: Tables<'records'>,
    previousRecord: Tables<'records'>,
    labe: string,
  ): string => {
    const key = getKeyWithLabel(labe)
    const currentValue = currentRecord[key]
    const previousValue = previousRecord[key]
    if (!currentValue || !previousValue) return ''
    const diff = currentValue - previousValue
    return diff === 0 ? '=' : `${diff > 0 ? '+' : '-'}${Math.abs(diff)}`
  }

  const getValue = (record: Tables<'records'>, label: string): number => {
    const key = getKeyWithLabel(label)
    return record[key] ?? 0
  }

  const options = useMemo(() => {
    if (showCompare && previousData) {
      return produce(DEFAULT_OPTIONS, (draft) => {
        draft.scales.r.pointLabels.callback = (label: string) => {
          const diff = getDiff(data, previousData, label)
          return `${label.split(' ')[0]}${diff}`
        }
        draft.scales.r.pointLabels.font.size = 13
      })
    }
    if (variant === PolarAreaVariants.WithPoints) {
      return produce(DEFAULT_OPTIONS, (draft) => {
        draft.scales.r.pointLabels.callback = (label: string) => {
          const value = getValue(data, label)
          return `${label.split(' ')[0]} ${value}`
        }
        draft.scales.r.pointLabels.font.size = 13
      })
    }
    if (variant === PolarAreaVariants.Borderless) return BORDERLESS_OPTIONS
    return DEFAULT_OPTIONS
  }, [variant, showCompare, previousData, data])

  const formattedData = getGraphFromRecord(data, dict, titles)
  return <PolarArea data={formattedData} options={options} />
}
