import config from '@/config'

const getRedirectUrl = () => {
  let url = config.site_url ?? config.vercel_url ?? 'http://localhost:3000/'
  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`
  // Make sure to include a trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`
  return `${url}auth/callback/`
}

export default getRedirectUrl
