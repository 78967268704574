import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect
      width={20}
      height={20}
      x={2}
      y={2}
      fill="#0F766E"
      opacity={0.12}
      rx={5}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="m12.193 16.499-.474.489a3.278 3.278 0 0 1-4.738 0c-1.308-1.35-1.308-3.538 0-4.888l1.13-1.166a3.026 3.026 0 0 1 4.373 0l.183.188m-.86-3.62.474-.49a3.278 3.278 0 0 1 4.738 0c1.308 1.35 1.308 3.538 0 4.888l-1.13 1.166a3.026 3.026 0 0 1-4.373 0l-.182-.188M10 22h4c2.8 0 4.2 0 5.27-.545a5 5 0 0 0 2.185-2.185C22 18.2 22 16.8 22 14v-4c0-2.8 0-4.2-.545-5.27a5 5 0 0 0-2.185-2.185C18.2 2 16.8 2 14 2h-4c-2.8 0-4.2 0-5.27.545A5 5 0 0 0 2.545 4.73C2 5.8 2 7.2 2 10v4c0 2.8 0 4.2.545 5.27a5 5 0 0 0 2.185 2.185C5.8 22 7.2 22 10 22Z"
    />
  </svg>
)
export default SvgComponent
