import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Typography } from '@/components/ui/typography'

interface MembershipCardProps {
  title: string
  price: string
  priceDescription?: string
  buttonLabel?: string
}

export const MembershipCard = ({
  title,
  price,
  priceDescription,
  buttonLabel,
}: MembershipCardProps) => {
  return (
    <Card>
      <CardContent className="px-6 py-5 flex justify-between items-start">
        <div className="flex flex-col gap-2">
          <Typography size="small" weight="medium">
            {title}
          </Typography>
          <div>
            <Typography weight="light" size="xsmall">
              {price}
            </Typography>
            {priceDescription && (
              <Typography weight="light" size="xsmall" variant="muted">
                {priceDescription}
              </Typography>
            )}
          </div>
        </div>
        {buttonLabel && (
          <Button size="sm" variant="outline">
            Best value
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
