import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F766E"
      d="M6.492 16H5.2c-1.12 0-1.68 0-2.108-.219a2 2 0 0 1-.874-.873C2 14.48 2 13.92 2 12.8v-1.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 8 4.08 8 5.199 8H6.492c.426 0 .64 0 .837-.04A2 2 0 0 0 7.8 7.8L14 14v1.691c0 2.158 0 3.238-.267 3.7a2 2 0 0 1-2.622.795c-.479-.238-1.077-1.136-2.274-2.931-.237-.355-.355-.533-.498-.675a2 2 0 0 0-1.01-.54C7.131 16 6.92 16 6.492 16Z"
      opacity={0.12}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M17 10c.598.399 1 1.145 1 2 0 .358-.07.697-.197 1M18 6c1.793 1.197 3 3.436 3 6a7.794 7.794 0 0 1-.59 3c-.147.35-.318.685-.511 1M14 14v1.691c0 2.158 0 3.238-.267 3.7a2 2 0 0 1-2.622.795c-.479-.238-1.077-1.136-2.274-2.931-.237-.355-.355-.533-.498-.675a2 2 0 0 0-1.01-.54C7.131 16 6.92 16 6.492 16H5.2c-1.12 0-1.68 0-2.108-.219a2 2 0 0 1-.874-.873C2 14.48 2 13.92 2 12.8v-1.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.875C3.52 8 4.08 8 5.199 8H6.492c.426 0 .64 0 .837-.04A2 2 0 0 0 7.8 7.8M14 14 7.8 7.8M14 14l7 7M3 3l4.8 4.8m1.873-2.3c.67-.982 1.087-1.512 1.438-1.687a2 2 0 0 1 2.622.794C14 5.07 14 6.15 14 8.307V10"
    />
  </svg>
)
export default SvgComponent
