import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m3.333 4.167.491 8.343c.114 1.93.17 2.895.552 3.642a4 4 0 0 0 1.956 1.845c.768.336 1.735.336 3.668.336v0c1.934 0 2.9 0 3.668-.336a4 4 0 0 0 1.956-1.845c.382-.747.438-1.712.552-3.642l.49-8.343m-13.333 0H1.667m1.666 0h13.334m0 0h1.666m-5 0-.286-.86c-.197-.59-.295-.884-.477-1.103a1.5 1.5 0 0 0-.602-.433c-.265-.104-.576-.104-1.198-.104H9.23c-.622 0-.933 0-1.198.104a1.5 1.5 0 0 0-.601.433c-.183.219-.281.514-.478 1.104l-.286.859"
    />
  </svg>
)
export default SvgComponent
