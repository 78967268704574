import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12ZM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm3.333 7c.92 0 1.667-.746 1.667-1.667A3.333 3.333 0 0 0 13.667 13h-3.334A3.333 3.333 0 0 0 7 16.333C7 17.253 7.746 18 8.667 18h6.666Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
