import { IconProps } from '../types'

const ActiveHome = ({ size, onClick, className }: IconProps) => {
  return (
    <div style={{ fontSize: size }} className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 25 24"
        fill="none"
      >
        <g clipPath="url(#clip0_1148_1872)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.75 12.2531C1.75 10.5587 1.75 9.71147 1.96548 8.92927C2.15638 8.23631 2.47024 7.58329 2.89209 7.00134C3.36828 6.34444 4.02983 5.8152 5.35293 4.75672L6.50293 3.83672C8.64324 2.12447 9.71339 1.26835 10.9002 0.94002C11.9471 0.650409 13.0529 0.650409 14.0998 0.94002C15.2866 1.26835 16.3568 2.12447 18.4971 3.83672L19.6471 4.75672C20.9702 5.8152 21.6317 6.34444 22.1079 7.00134C22.5298 7.58329 22.8436 8.23631 23.0345 8.92927C23.25 9.71147 23.25 10.5587 23.25 12.2531V13.1495C23.25 16.5098 23.25 18.19 22.596 19.4735C22.0208 20.6024 21.1029 21.5203 19.9739 22.0956C18.6905 22.7495 17.0103 22.7495 13.65 22.7495H11.35C7.98969 22.7495 6.30953 22.7495 5.02606 22.0956C3.89708 21.5203 2.9792 20.6024 2.40396 19.4735C1.75 18.19 1.75 16.5098 1.75 13.1495V12.2531ZM9.25 15.25C8.83579 15.25 8.5 15.5858 8.5 16C8.5 16.4142 8.83579 16.75 9.25 16.75H15.75C16.1642 16.75 16.5 16.4142 16.5 16C16.5 15.5858 16.1642 15.25 15.75 15.25H9.25Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1148_1872">
            <rect
              width="1em"
              height="1em"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
export default ActiveHome
