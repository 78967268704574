import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.67"
      d="m2.5 15 2.77-2.77c.782-.782 1.173-1.173 1.625-1.322a2 2 0 0 1 1.224-.009c.454.143.85.528 1.644 1.298l2.31 2.242m5.844.3-.81-.81c-.792-.792-1.188-1.188-1.645-1.336a1.999 1.999 0 0 0-1.236 0c-.457.148-.853.545-1.645 1.337l-.509.509m3.345 3.345-3.345-3.345M14.167 2.5v5m0 0-2.083-2.083M14.167 7.5l2.083-2.083M10 1.667h-.416v0c-1.783 0-2.674 0-3.407.188a6 6 0 0 0-4.322 4.322c-.188.733-.188 1.624-.188 3.406V10c0 2.171 0 3.257.278 4.138a6 6 0 0 0 3.918 3.918c.88.277 1.966.277 4.137.277h.417c1.782 0 2.673 0 3.406-.187a6 6 0 0 0 4.323-4.323c.188-.733.188-1.624.188-3.406v0V10"
    />
  </svg>
)
export default SvgComponent
