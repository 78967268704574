import { TEMPLATE } from '@/constants'

const generateInsightTemplate = (records: Record<string, number>) => {
  // Create a new template string using the keys of the filtered records
  const templateKeys = Object.keys(records)
  return TEMPLATE.split('\n')
    .filter((line) => {
      const key = line.split(': ')[1]?.replace('{', '').replace('}.', '')
      return !key || templateKeys.includes(key)
    })
    .join('\n')
}

export default generateInsightTemplate
