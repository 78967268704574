import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.666 8v5.333a1.333 1.333 0 0 0 1.333 1.334h8a1.334 1.334 0 0 0 1.334-1.334V8m-2.667-4L7.999 1.333m0 0L5.333 4m2.666-2.667V10"
    />
  </svg>
)
export default SvgComponent
