import { IconProps } from '../types'

const ActiveHistory = ({ size, className }: IconProps) => {
  return (
    <div style={{ fontSize: size }} className={className}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 12C1.75 6.06294 6.56294 1.25 12.5 1.25C18.4371 1.25 23.25 6.06294 23.25 12C23.25 17.9371 18.4371 22.75 12.5 22.75C6.56294 22.75 1.75 17.9371 1.75 12ZM13.25 8C13.25 7.58579 12.9142 7.25 12.5 7.25C12.0858 7.25 11.75 7.58579 11.75 8V12C11.75 12.2508 11.8753 12.4849 12.084 12.624L15.084 14.624C15.4286 14.8538 15.8943 14.7607 16.124 14.416C16.3538 14.0714 16.2607 13.6057 15.916 13.376L13.25 11.5986V8Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}

export default ActiveHistory
