import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F766E"
      d="M3 8.12c0-1.13 0-1.694.178-2.187a3 3 0 0 1 .747-1.15c.378-.362.894-.591 1.926-1.05L9.4 2.155c.957-.425 1.435-.638 1.933-.722a4 4 0 0 1 1.332 0c.498.084.976.297 1.933.722l3.55 1.578c1.032.459 1.548.688 1.926 1.05a3 3 0 0 1 .747 1.15C21 6.426 21 6.99 21 8.119v5.456c0 1.252 0 1.878-.173 2.447a4 4 0 0 1-.737 1.377c-.377.459-.898.806-1.94 1.5l-3.487 2.326c-.963.641-1.444.962-1.964 1.087-.46.11-.938.11-1.398 0-.52-.125-1.001-.446-1.964-1.087L5.85 18.9c-1.042-.695-1.563-1.042-1.94-1.5a4.001 4.001 0 0 1-.737-1.378C3 15.453 3 14.827 3 13.575V8.119Z"
      opacity={0.12}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="m9 12 2 2 4.5-4.5M9.4 2.155 5.85 3.733c-1.031.459-1.547.688-1.925 1.05a3 3 0 0 0-.747 1.15C3 6.426 3 6.99 3 8.119v5.456c0 1.252 0 1.878.173 2.447.152.503.403.97.737 1.377.377.459.898.806 1.94 1.5l3.487 2.326c.963.641 1.444.962 1.964 1.087.46.11.938.11 1.398 0 .52-.125 1.001-.446 1.964-1.087L18.15 18.9c1.042-.695 1.563-1.042 1.94-1.5a4 4 0 0 0 .737-1.378c.173-.569.173-1.195.173-2.447V8.119c0-1.129 0-1.693-.178-2.186a3 3 0 0 0-.747-1.15c-.378-.362-.894-.591-1.925-1.05L14.6 2.155c-.958-.425-1.436-.638-1.934-.722a4 4 0 0 0-1.332 0c-.498.084-.976.297-1.933.722Z"
    />
  </svg>
)
export default SvgComponent
