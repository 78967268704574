import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F766E"
      d="M21.874 10.406a10.008 10.008 0 0 0-8.28-8.28c-.465-.074-.698-.111-.946-.018a1.097 1.097 0 0 0-.516.44C12 2.778 12 3.052 12 3.6v5.2c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C13.52 12 14.08 12 15.2 12h5.2c.548 0 .822 0 1.052-.132.183-.104.365-.318.44-.516.093-.248.056-.48-.018-.946Z"
      opacity={0.12}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M9 2.458C4.943 3.732 2 7.523 2 12c0 5.523 4.477 10 10 10 4.478 0 8.268-2.943 9.542-7M15.2 12h5.2c.548 0 .822 0 1.052-.132.183-.104.365-.318.44-.516.093-.248.056-.48-.018-.946a10.008 10.008 0 0 0-8.28-8.28c-.465-.074-.698-.111-.946-.018a1.097 1.097 0 0 0-.516.44C12 2.778 12 3.052 12 3.6v5.2c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C13.52 12 14.08 12 15.2 12Z"
    />
  </svg>
)
export default SvgComponent
