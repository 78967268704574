import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { LifeCategory } from '@/supabase'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatLifeCategories = (
  categories: Record<string, { title: string; description: string }>,
): Record<LifeCategory, string> => {
  return {
    [LifeCategory.Health]: categories.health.title,
    [LifeCategory.Growth]: categories.growth.title,
    [LifeCategory.Career]: categories.career.title,
    [LifeCategory.Friends]: categories.friends.title,
    [LifeCategory.Love]: categories.love.title,
    [LifeCategory.Motivation]: categories.motivation.title,
    [LifeCategory.Recreation]: categories.recreation.title,
    [LifeCategory.Environment]: categories.environment.title,
    [LifeCategory.Finances]: categories.finances.title,
  }
}
