import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M6.667.833v2.5m6.666-2.5v2.5m.833 3.334H5.833m8.333 7.5H12.5M10 18.333v0c2.171 0 3.257 0 4.137-.277a6 6 0 0 0 3.918-3.919c.278-.88.278-1.966.278-4.137v0c0-2.171 0-3.257-.277-4.138a6 6 0 0 0-3.919-3.918c-.88-.277-1.966-.277-4.137-.277v0c-2.171 0-3.257 0-4.138.277a6 6 0 0 0-3.918 3.918c-.277.881-.277 1.967-.277 4.138v0c0 2.171 0 3.257.277 4.137a6 6 0 0 0 3.918 3.919c.881.277 1.967.277 4.138.277Z"
    />
  </svg>
)
export default SvgComponent
