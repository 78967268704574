export const getNameInitials = (name: string) => {
  const nameSplitted = name.split(' ')
  if (nameSplitted.length < 2) {
    const firstNameLeadingLetter = name[0] ?? ''
    return firstNameLeadingLetter.toUpperCase()
  }

  const [firstName, lastName] = nameSplitted
  return `${firstName[0]}${lastName[0]}`.toUpperCase()
}
