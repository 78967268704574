/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { ClassValue } from 'clsx'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { getNameInitials } from '@/lib'
import { cn } from '@/lib/utils'

interface AvatarProps {
  className?: ClassValue
  avatar: string
  username: string
}

const AvatarCustom = ({ avatar, username, className }: AvatarProps) => {
  return (
    <Avatar className={cn('w-12 h-12', className)}>
      <AvatarImage src={avatar} asChild>
        <img src={avatar} />
      </AvatarImage>
      <AvatarFallback>{getNameInitials(username)}</AvatarFallback>
    </Avatar>
  )
}

export default AvatarCustom
