import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F766E"
      d="M2 12.8v-1.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 8 4.08 8 5.2 8h1.292c.426 0 .64 0 .837-.04a2 2 0 0 0 1.01-.54c.143-.143.261-.32.498-.675 1.197-1.796 1.796-2.694 2.274-2.931a2 2 0 0 1 2.622.793C14 5.071 14 6.15 14 8.308v7.383c0 2.159 0 3.238-.267 3.701a2 2 0 0 1-2.622.794c-.479-.238-1.077-1.135-2.274-2.931-.237-.355-.355-.532-.498-.675a2 2 0 0 0-1.01-.54C7.131 16 6.92 16 6.492 16H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 14.48 2 13.92 2 12.8Z"
      opacity={0.12}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M17 10c.598.4 1 1.145 1 2s-.402 1.6-1 2m1-8c1.793 1.198 3 3.436 3 6s-1.207 4.802-3 6M8.837 6.745v0c1.197-1.796 1.796-2.694 2.274-2.931a2 2 0 0 1 2.622.794C14 5.07 14 6.15 14 8.308v7.384c0 2.158 0 3.237-.267 3.7a2 2 0 0 1-2.622.794c-.479-.237-1.077-1.135-2.274-2.931v0c-.237-.355-.355-.532-.498-.674a2 2 0 0 0-1.01-.54C7.131 16 6.92 16 6.492 16H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 14.48 2 13.92 2 12.8v-1.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 8 4.08 8 5.2 8h1.292c.426 0 .64 0 .837-.04a2 2 0 0 0 1.01-.54c.143-.143.261-.32.498-.675Z"
    />
  </svg>
)
export default SvgComponent
