import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 3.333A2.667 2.667 0 0 0 12.665 6m-10 7.333.287-1.433c.14-.706.212-1.06.34-1.389a4 4 0 0 1 .444-.827c.202-.29.457-.545.966-1.054l5.963-5.963a1.886 1.886 0 0 1 2.667 0v0c.736.736.736 1.93 0 2.666L7.37 11.296c-.51.51-.764.764-1.055.967-.257.18-.535.328-.827.443-.33.129-.683.2-1.389.34l-1.433.287Z"
    />
  </svg>
)
export default SvgComponent
