import { cva,VariantProps } from 'class-variance-authority'
import { forwardRef,HTMLAttributes } from 'react'

import { cn } from '@/lib/utils'

const typographyVariants = cva('m-0', {
  variants: {
    variant: {
      default: 'text-foreground',
      primary: 'text-foreground', // alias for 'default'
      muted: 'text-muted-foreground',
      silenced: 'text-zinc-400',
      white: 'text-white',
      success: 'text-green-700',
      danger: 'text-[#DC2626]',
    },
    size: {
      '4xlarge': 'text-4xl',
      '2xlarge': 'text-2xl',
      xlarge: 'text-xl',
      large: 'text-lg',
      default: 'text-base',
      base: 'text-base', // alias for 'default'
      small: 'text-sm',
      xsmall: 'text-xs',
    },
    weight: {
      default: 'font-light',
      light: 'font-light',
      regular: 'font-normal', // alias for 'default'
      medium: 'font-normal', // Change to 'font-medium' if typography needs to be bolder
      semibold: 'font-semibold',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    weight: 'default',
  },
})

interface TypographyProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof typographyVariants> {}

const Typography = forwardRef<HTMLDivElement, TypographyProps>(
  ({ variant, size, className, weight, ...props }, ref) => {
    return (
      <div
        className={cn(typographyVariants({ variant, size, weight, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)

Typography.displayName = 'Typography'

export { Typography, typographyVariants }
