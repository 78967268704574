import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m2.667 3.333.393 6.675c.05.835.074 1.253.159 1.602a4 4 0 0 0 3.173 2.992c.354.065.772.065 1.609.065v0c.836 0 1.254 0 1.609-.065a4 4 0 0 0 3.173-2.992c.085-.35.11-.767.158-1.602l.393-6.675m-10.667 0H1.334m1.333 0h10.667m0 0h1.333m-4 0-.12-.359c-.196-.59-.294-.885-.477-1.103a1.5 1.5 0 0 0-.601-.434c-.265-.104-.576-.104-1.198-.104h-.54c-.623 0-.934 0-1.198.104a1.5 1.5 0 0 0-.602.434c-.182.218-.28.513-.477 1.103l-.12.36m1.333 3.333v4.666m2.667-4.666v2.666"
    />
  </svg>
)
export default SvgComponent
