import { Button } from '@/components/ui/button'
import { Typography } from '@/components/ui/typography'

interface BalanceCardHeaderProps {
  title: string
  description: string
}

export const BalanceCardHeader = ({
  title,
  description,
}: BalanceCardHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col">
        <Typography weight="medium">{title}</Typography>
        <Typography weight="light" size="xsmall" variant="muted">
          {description}
        </Typography>
      </div>
      {/* <Button size="icon" variant="secondary">
        <Edit2 size={16} />
      </Button> */}
    </div>
  )
}
