export const polarGraphLabels = [
  'health',
  'growth',
  'career',
  'friends',
  'love',
  'motivation',
  'recreation',
  'environment',
  'finances',
]
