import { Loader2Icon } from 'lucide-react'

const LoaderScreen = () => {
  return (
    <div className="flex h-[100dvh] flex-1 items-center justify-center text-primary">
      <Loader2Icon className="h-16 w-16 animate-spin" />
    </div>
  )
}

export default LoaderScreen
