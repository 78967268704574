import { LanguageOptions } from '@/app/dictionaries'
import { FormRecordValues } from '@/store/type'
import { LifeCategory, Tables } from '@/supabase'

interface GetMultipleInsightsTemplateProps {
  currentRecord: FormRecordValues
  previousRecord: FormRecordValues
  previousActivities: Tables<'activity'>[]
  lang: LanguageOptions
}

const mapLabelToCategory = {
  health: 'Health',
  growth: 'Personal Growth',
  career: 'Career',
  friends: 'Friends & Family',
  love: 'Love',
  motivation: 'Motivation',
  recreation: 'Recreation',
  environment: 'Environment',
  finances: 'Finances',
}

const getMultipleInsightsTemplate = ({
  currentRecord,
  previousRecord,
  previousActivities,
  lang,
}: GetMultipleInsightsTemplateProps) => {
  const formatActivities = previousActivities.reduce(
    (acc, activity) => {
      const category = activity.category as LifeCategory
      acc[category] = [...(acc[category] ?? []), activity]
      return acc
    },
    {} as Record<LifeCategory, Tables<'activity'>[]>,
  )
  const previousActivitiesString = Object.entries(formatActivities)
    .map(([category, activities]) => {
      const activitiesList = activities
        .map(
          (activity) =>
            `- ${activity.task} (${activity.completed ? 'Completed' : 'Uncompleted'})`,
        )
        .join('\n')
      return `${category}:\n${activitiesList}`
    })
    .join('\n\n')

  const currentCategoriesResult = Object.entries(currentRecord).map(
    ([key, value]) => `- ${mapLabelToCategory[key as LifeCategory]}: ${value}.`,
  )

  const previousCategoriesResult = Object.entries(previousRecord).map(
    ([key, value]) => `- ${mapLabelToCategory[key as LifeCategory]}: ${value}.`,
  )

  return `Here's my wheel of life result:
${currentCategoriesResult.join('\n')}

My previous wheel of life result is this:
${previousCategoriesResult.join('\n')}

I planned these activities to complete from my previous wheel of life:
${previousActivitiesString}

Respond in ${lang} language.`
}

export default getMultipleInsightsTemplate
