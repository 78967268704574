import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M12.5 4c-.697 0-1.387-.262-1.814-.813l-.452-.583a2 2 0 0 0-2.58-.507l-1.307.754a2 2 0 0 0-.851 2.489l.279.683c.264.645.147 1.37-.203 1.974v0C5.223 8.6 4.65 9.07 3.96 9.165l-.73.1a2 2 0 0 0-1.73 1.98v1.51a2 2 0 0 0 1.73 1.98l.73.1c.69.095 1.263.565 1.612 1.168v0c.35.604.467 1.329.203 1.974l-.28.683a2 2 0 0 0 .852 2.49l1.306.754a2 2 0 0 0 2.581-.508l.452-.583c.427-.551 1.117-.813 1.814-.813v0c.697 0 1.387.262 1.814.813l.452.583a2 2 0 0 0 2.58.507l1.307-.754a2 2 0 0 0 .852-2.489l-.28-.683c-.264-.645-.147-1.37.203-1.974v0c.349-.603.922-1.073 1.612-1.168l.73-.1a2 2 0 0 0 1.73-1.98v-1.51a2 2 0 0 0-1.73-1.98l-.73-.1c-.69-.095-1.263-.565-1.612-1.168v0c-.35-.604-.467-1.329-.203-1.974l.28-.683a2 2 0 0 0-.852-2.49l-1.306-.753a2 2 0 0 0-2.581.507l-.452.583C13.887 3.738 13.197 4 12.5 4v0Z" />
      <path d="M15.5 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </g>
  </svg>
)
export default SvgComponent
