import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g
      opacity={0.9}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <circle
        cx={16}
        cy={16}
        r={15}
        stroke="#2FA37D"
        strokeDasharray="4 4"
        strokeWidth={2}
      />
      <circle
        cx={16}
        cy={16}
        r={10}
        fill="url(#a)"
        transform="rotate(180 16 16)"
      />
    </g>
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 10 -10 0 16 16)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#059669" />
        <stop offset={1} stopColor="#059669" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
)
export default SvgComponent
