import { polarGraphLabels } from '@/constants'
import { DictionaryType } from '@/locales/dictionaries.types'
import { LifeCategory, Tables } from '@/supabase'

const getGraphFromRecord = (
  record: Tables<'records'>,
  dic: DictionaryType,
  titles: Record<LifeCategory, string>,
) => {
  const {
    career,
    environment,
    finances,
    friends,
    growth,
    health,
    love,
    motivation,
    recreation,
  } = record
  const data = [
    health,
    growth,
    career,
    friends,
    love,
    motivation,
    recreation,
    environment,
    finances,
  ]
  const backgroundColor = [
    'rgba(147, 197, 253, 0.6)',
    'rgba(165, 180, 252, 0.6)',
    'rgba(167, 139, 250, 0.6)',
    'rgba(240, 171, 252, 0.6)',
    'rgba(244, 114, 182, 0.6)',
    'rgba(252, 165, 165, 0.6)',
    'rgba(217, 249, 157, 0.6)',
    'rgba(134, 239, 172, 0.6)',
    'rgba(45, 212, 191, 0.6)',
  ]

  const { filteredBackgroundColor, filteredData, filteredLabels } = data.reduce(
    (acc, curr, i) => {
      if (curr) {
        acc.filteredData.push(curr)
        const categoryKey = polarGraphLabels[
          i
        ] as keyof typeof dic.life_categories
        const label = titles[categoryKey as keyof typeof titles]
        acc.filteredBackgroundColor.push(backgroundColor[i])
        acc.filteredLabels.push(label)
      }
      return acc
    },
    {
      filteredData: [] as number[],
      filteredBackgroundColor: [] as string[],
      filteredLabels: [] as string[],
    },
  )

  return {
    labels: filteredLabels,
    datasets: [
      {
        data: filteredData,
        backgroundColor: filteredBackgroundColor,
        borderWidth: 1,
      },
    ],
  }
}

export default getGraphFromRecord
