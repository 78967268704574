import { ChevronLeft } from 'lucide-react'
import Link from 'next/link'

import { Typography } from '@/components/ui/typography'

interface SecondaryPageTitleProps {
  title: string
  href: string
}
export const SecondaryPageTitle = ({
  title,
  href,
}: SecondaryPageTitleProps) => {
  return (
    <div className="flex justify-between items-center">
      <Link href={href}>
        <ChevronLeft size={24} />
      </Link>
      <Typography weight="medium" className="text-center flex-1">
        {title}
      </Typography>
      <div className="w-6"></div>
    </div>
  )
}
