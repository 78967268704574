import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0F766E"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12c0 1.499.33 2.921.921 4.197.467 1.008.91 1.867.584 3-.157.546-.55 1.083-.596 1.65a1 1 0 0 0 1.16 1.069c1.309-.218 1.939-1.259 3.457-.902.164.04.593.192 1.45.5.908.324 1.885.486 3.024.486Z"
      opacity={0.12}
    />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M12.5 16a.5.5 0 0 1-1 0m1 0a.5.5 0 0 0-1 0m1 0h-1M10 10.5V10a2 2 0 1 1 4 0v.121c0 .563-.223 1.102-.621 1.5L12 13m10-1c0 5.523-4.477 10-10 10-1.14 0-2.116-.162-3.024-.487-.857-.307-1.286-.46-1.45-.499-1.518-.357-2.148.684-3.456.902a1 1 0 0 1-1.161-1.069c.046-.567.439-1.104.596-1.65.325-1.133-.117-1.992-.584-3A9.96 9.96 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10Z"
    />
  </svg>
)
export default SvgComponent
