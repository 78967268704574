import { IconProps } from '../types'

const HomeIcon = ({ size = 24, className }: IconProps) => {
  return (
    <div style={{ fontSize: size }} className={className}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M9.5 16H15.5M7.50244 3.99805L5.50244 5.59805C4.39986 6.48011 3.84857 6.92115 3.45174 7.46857C3.1002 7.95353 2.83865 8.49771 2.67957 9.07517C2.5 9.72701 2.5 10.433 2.5 11.845V14C2.5 16.8003 2.5 18.2004 3.04497 19.27C3.52433 20.2108 4.28924 20.9757 5.23005 21.455C6.29961 22 7.69974 22 10.5 22H14.5C17.3003 22 18.7004 22 19.77 21.455C20.7108 20.9757 21.4757 20.2108 21.955 19.27C22.5 18.2004 22.5 16.8003 22.5 14V11.845C22.5 10.433 22.5 9.72701 22.3204 9.07517C22.1613 8.49771 21.8998 7.95353 21.5483 7.46857C21.1514 6.92115 20.6001 6.48011 19.4976 5.59805L17.4976 3.99805C15.714 2.57118 14.8222 1.85774 13.8332 1.58413C12.9608 1.34279 12.0392 1.34279 11.1668 1.58413C10.1778 1.85774 9.28603 2.57118 7.50244 3.99805Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  )
}

export default HomeIcon
