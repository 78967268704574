import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <circle cx={12} cy={9} r={7} fill="#0F766E" opacity={0.12} />
    <path
      stroke="#047857"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33}
      d="M12 19c5.523 0 10-4.477 10-10a9.967 9.967 0 0 0-2.859-7M12 19a9.967 9.967 0 0 1-7-2.859M12 19v3m0 0h-2m2 0h2m5-13A7 7 0 1 1 5 9a7 7 0 0 1 14 0Z"
    />
  </svg>
)
export default SvgComponent
